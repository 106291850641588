* {
  font-family: "Montserrat";
  color: var(--text-color);
}
body {
  transition: .1s ease;
  margin: 0;
  padding: 0;
  top: 0;
  background-color: var(--background-color);
}
p {
  color: var(--text-color);
  margin: 0.2rem;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text {
  font-size: 1rem;
  font-weight: 500;
}
.title {
  font-size: 2rem;
  font-weight: bolder;
}
.sub-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.distance-above {
  margin-top: 1.5rem;
  margin-bottom: 0.1rem;
}
hr {
  margin: 1rem 0px;
  border: 0;
  border-top: 1px solid var(--text-color);
}
.hr-small {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.content {
  padding: 1rem;
}
.btn {
  text-align: center;
  text-decoration: none;
  transition: 0.1s ease;
  margin: 0.2rem;
  padding: 0.5rem;
  background: none;
  border: 1px solid;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}
.btn-small {
  padding: 0.4rem;
  font-size: 0.8rem;
}
.btn-large {
  padding: 0.6rem;
  font-size: 1.2rem;
}
.btn-primary {
  background-color: var(--primary-color);
  color: var(--background-color);
}
.btn-primary:hover {
  /* background-color: var(--background-color); */
  /* color: var(--primary-color); */
  border: 2px;
}
.btn-secondary {
  background-color: var(--background-color);
  color: var(--text-color);
}
.btn-secondary:hover {
  border: 2px solid var(--text-color);
  /* background-color: var(--text-color); */
  /* color: var(--background-color); */
}
.section {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
}
.to-right {
  justify-content: flex-end;
}
.to-center {
  justify-content: center;
}
.to-left {
  justify-content: flex-start;
}

.modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal .modal-content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 50vh;
  width: 50vw;
  border-radius: 1rem;
  padding: 1rem;
  background-color: var(--background-color);
  overflow: hidden;
}
.modal .modal-content .modal-close-btn {
  margin-top: auto;
}
.modal-closed {
  display: none !important;
}
.modal .modal-content .modal-title {
  font-weight: 600;
  font-size: 1.3rem;
  padding-bottom: 1rem;
}

@media (min-width: 48.1rem) and (min-height: 30rem) {
  .content {
    margin-left: 5rem;
  }
  .content:last-child {
    margin-bottom: 0;
  }
  .row .col-2 {
    width: 50%;
  }
  .row .col-3 {
    width: 33%;
  }
}
@media (max-width: 48rem), (max-height: 30rem) {
  .modal .modal-content {
    height: 75vh;
    width: 75vw;
  }
  .content {
    margin: 0px;
  }
  .content:last-child {
    margin-bottom: 8rem;
  }
  .row {
    flex-direction: column;
  }
  .row div {
    width: 100%;
  }
}

::-webkit-scrollbar {
  height: 0.4rem;
  width: 0.4rem;
}
::-webkit-scrollbar-track {
  background: var(--background-color);
}
::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: var(--primary-color);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--text-color);
}
